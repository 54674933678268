import AMap from "vue-amap";
// import "echarts/map/js/china.js";
export default {
	name: "platformStatistics",
	data() {
		return {
			count1: 0,
			count2: 0,
			count3: 0,
			count4: 0,
			count5: 0,
			projectEchartsY: [],
			projectEchartsX: [],
			//				res:[],
			count: 1,
			slotStyle: {
				padding: "2px 8px",
				background: "#eee",
				color: "#333",
				border: "1px solid #aaa"
			},
			zoom: 14,
			center: [117.183708, 39.080246],
			markers: [],
			renderMarker: {
				position: [121.5273285, 116.4224177287],
				contentRender: (h, instance) => {
					return h(
						"div",
						{
							style: {
								background: "#80cbc4",
								whiteSpace: "nowrap",
								border: "solid #ddd 1px",
								color: "#f00"
							},
							on: {
								click: () => {
									const position = this.renderMarker.position;
									this.renderMarker.position = [
										position[0] + 0.002,
										position[1] - 0.002
									];
								}
							}
						},
						["marker inner text"]
					);
				}
			}
		};
	},

	created() {
		//			this.convertData();
		this.token = this.getCookie("token");
		this.getProjectsts1();
		this.getProjectsts2();
		this.getProjectsts3();
		this.getProjectsts4();
		this.getProjectsts5();
		// this.setMap();
		// this.getMapData()
		this.getMapData();
	},
	mounted() {
		this.drawLine();
		let echar2 = this.$echarts.init(document.getElementById("echar-2"));
		let echar3 = this.$echarts.init(document.getElementById("echar-3"));
		var _this = this;
		echar2.on("click", function (e) {
			_this.$store.commit("newResult", e.name);
			_this.$store.commit("getId", e.data.id);
			_this.$router.push("/smoPersonnel");
		});
		echar3.on("click", function (e) {
			_this.$store.commit("newResult", e.name);
			_this.$store.commit("getId", e.data.id);
			_this.$router.push("/organizationProject");
		});
	},
	methods: {
		aaaa(index) {
			alert(index);
		},
		getProjectsts1() {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/platInCount",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.count1 = response.data.data;
					}
				});
		},
		getProjectsts2() {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/platProCount",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.count2 = response.data.data;
					}
				});
		},
		getProjectsts3() {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/platBidCount",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.count3 = response.data.data;
					}
				});
		},
		getProjectsts4() {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/platEnCount",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.count4 = response.data.data;
					}
				});
		},
		getProjectsts5() {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/platCrcCount",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.count5 = response.data.data;
					}
				});
		},
		convertData(data) {
			var _this = this;
			var res = [];
			for (var i = 0; i < data.length; i++) {
				var geoCoord = _this.geoCoordMap[data[i].name];
				if (geoCoord) {
					res.push({
						name: data[i].name,
						value: geoCoord.concat(data[i].value)
					});
				}
			}
			return res;
		},
		drawLine() {
			//console.log(res)
			var _this = this;
			// 基于准备好的dom，初始化echarts实例
			//   let echar1 = this.$echarts.init(document.getElementById("echar-1"));
			let echar2 = this.$echarts.init(document.getElementById("echar-2"));
			let echar3 = this.$echarts.init(document.getElementById("echar-3"));
			// 绘制图表
			_this.$http
				.post(
					"/API/PLAT/platCountA",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						console.log(response.data.data);
					}
				});
			// 绘制图表
			_this.$http
				.post(
					"/API/PLAT/platCountB",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						console.log(response)
						echar2.setOption({
							tooltip: {
								trigger: "axis",
								axisPointer: {
									// 坐标轴指示器，坐标轴触发有效
									type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
								}
							},
							legend: {
								data: ["CRC人数"]
							},
							grid: {
								left: "3%",
								right: "4%",
								bottom: "3%",
								containLabel: true
							},
							xAxis: {
								type: "value",
								min: 0,
								max: 80,
								interval: 10,
								axisLine: {
									lineStyle: {
										color: "##CBD1DA"
									}
								}
							},
							yAxis: {
								type: "category",
								data: response.data.data.y,
								axisLine: {
									lineStyle: {
										color: "##CBD1DA"
									}
								},
								axisLabel: {
									interval: 0,
									//                formatter: function(params) {
									//                  var newParamsName = "";
									//                  var paramsNameNumber = params.length;
									//                  var provideNumber = 5;
									//                  var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
									//                  if (paramsNameNumber > provideNumber) {
									//                    for (var p = 0; p < rowNumber; p++) {
									//                      var tempStr = "";
									//                      var start = p * provideNumber;
									//                      var end = start + provideNumber;
									//                      if (p == rowNumber - 1) {
									//                        tempStr = params.substring(start, paramsNameNumber);
									//                      } else {
									//                        tempStr = params.substring(start, end) + "\n";
									//                      }
									//                      newParamsName += tempStr;
									//                    }
									//                  } else {
									//                    newParamsName = params;
									//                  }
									//                  return newParamsName;
									//                }
								}
							},

							series: [
								{
									name: "CRC人数",
									type: "bar",
									barWidth: "50%",
									color: "#539CF2",
									label: {
										normal: {
											show: true,
											position: "insideRight"
										}
									},
									data: response.data.data.x
								}
							]
						});
					}
				});

			// 绘制图表
			_this.$http
				.post(
					"/API/PLAT/platCountCPC",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						echar3.setOption({
							tooltip: {
								trigger: "axis",
								axisPointer: {
									// 坐标轴指示器，坐标轴触发有效
									type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
								}
							},
							legend: {
								data: ["项目数"]
							},
							grid: {
								left: "3%",
								right: "4%",
								bottom: "3%",
								containLabel: true
							},
							xAxis: {
								type: "value",
								min: 0,
								max: 500,
								interval: 50,
								axisLine: {
									lineStyle: {
										color: "##CBD1DA"
									}
								}
							},
							yAxis: {
								type: "category",
								data: response.data.data.y,
								axisLine: {
									lineStyle: {
										color: "##CBD1DA"
									}
								},
								axisLabel: {
									interval: 0,
									//                formatter: function(params) {
									//                  var newParamsName = "";
									//                  var paramsNameNumber = params.length;
									//                  var provideNumber = 5;
									//                  var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
									//                  if (paramsNameNumber > provideNumber) {
									//                    for (var p = 0; p < rowNumber; p++) {
									//                      var tempStr = "";
									//                      var start = p * provideNumber;
									//                      var end = start + provideNumber;
									//                      if (p == rowNumber - 1) {
									//                        tempStr = params.substring(start, paramsNameNumber);
									//                      } else {
									//                        tempStr = params.substring(start, end) + "\n";
									//                      }
									//                      newParamsName += tempStr;
									//                    }
									//                  } else {
									//                    newParamsName = params;
									//                  }
									//                  return newParamsName;
									//                }
								}
							},
							series: [
								{
									name: "项目数",
									type: "bar",
									barWidth: "50%",
									color: "#539CF2",
									label: {
										normal: {
											show: true,
											position: "insideRight"
										}
									},
									data: response.data.data.x
								}
							]
						});
					}
				});
		},
		toPer() {
			this.$router.push("/organizationPersonnel");
		},
		toSmo() {
			this.$router.push("/smoPersonnel");
			this.$store.commit("newResult", "");
		},
		toPro() {
			this.$router.push("/organizationProject");
			this.$store.commit("newResult", "");
		},
		// 当前国家getter方法
		contry: function () {
			return this.markerGroups[this.i][0].contry;
		},
		getMapData: function () {
			var _this = this;
			// 基于准备好的dom，初始化echarts实例
			// let echar1 = this.$echarts.init(document.getElementById("echar-1"));
			// 绘制图表
			_this.$http
				.post(
					"/API/PLAT/platCountA",
					{},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.markers = response.data.data;
					}
				});
		}
	},
};
